'use client';

import { useUser } from '@auth0/nextjs-auth0/client';
import Button from '@mui/joy/Button';
import { useRouter } from 'next/router';
import Stack from '@mui/joy/Stack';
import Link from 'next/link';
import { useUserTrackIdentify } from '@/hooks/useUserTrackIdentify';
import { trackEventAmplitude } from '@/utils/trackEventsAmplitude';

export default function Home() {
  const user = useUser();
  const router = useRouter();

  useUserTrackIdentify(user.user?.sub || '', user.user?.email || '');

  const handleLoginAndVerifyNavigation = () => {
    const loginAndVerifyUri =
      '/api/auth/login?' + new URLSearchParams({ operation: 'verify' });

    trackEventAmplitude('auth', 'USER_BEING_OPERATION', {
      event_properties: {
        operation: 'verify',
      },
    });

    router.push(loginAndVerifyUri);
  };
  const handleLogoutNavigation = () => {
    trackEventAmplitude('auth', 'USER_LOGOUT');

    router.push('/api/auth/logout');
  };

  return (
    <Stack margin={2} width={600}>
      <Stack direction={'row'} spacing={2} marginBottom={2}>
        <Button onClick={handleLoginAndVerifyNavigation}>
          Logueate con Digito ID
        </Button>
      </Stack>

      <Stack direction={'row'} spacing={2} marginBottom={2}>
        <Button color="danger" onClick={handleLogoutNavigation}>
          Logout
        </Button>
      </Stack>

      {user.isLoading && <div>Loading...</div>}

      {user.user && (
        <>
          <br />
          <br />
          <h2>User Status</h2>
          <div>{user.user?.email}</div>
          <div>
            <Link href={'parking'}>Parking Spots</Link>
          </div>
        </>
      )}
    </Stack>
  );
}
