import { useRef, useCallback, useEffect } from 'react';
import { setUser } from '@sentry/nextjs';
import { amplitudeSingleton } from '@/config/amplitude';

export const useUserTrackIdentify = (userId: string, userEmail: string) => {
  const instanceRef = useRef(amplitudeSingleton());

  const handleUserInitialization = useCallback(() => {
    instanceRef.current.setUserId(userId);
    setUser({
      id: userId,
      email: userEmail,
    });
  }, [userEmail, userId]);

  useEffect(() => {
    if (userId && userEmail) {
      handleUserInitialization();
    }
  }, [handleUserInitialization, userEmail, userId]);
};
