import { Types } from '@amplitude/analytics-browser';
import { amplitudeSingleton } from '@/config/amplitude';

export const trackEventAmplitude = (
  context: string,
  eventName: string,
  params?: Omit<Types.BaseEvent, 'event_type'>
) => {
  const instance = amplitudeSingleton();
  instance.track({
    ...params,
    event_type: eventName,
    event_properties: {
      context,
      ...params?.event_properties,
    },
  });
};
